
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { getRecommendKeyword } from '@/api/mypage';
import { getHotSearchKeyword } from '@/api/searchLog';
import Calendar from '@/components/Calendar/index.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { getAiStoreInfoList } from '@/api/storeInfo';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';

@Component({
  components: {
    Calendar,
    InfiniteLoading,
  },
})

export default class extends Vue {
  mounted() {
    this.getHotSearchKeyword(); // 인기 검색어
    this.getRecommendKeyword(); // 추천 검색어
    this.getAiItemList(); // 여기 어때요
    this.slotVisibleDefault = true;
    this.slotVisble = false;
  }

  private slotVisibleDefault = true;

  private slotVisble = false;

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private hotSearchKeyword = [];

  private handleSearch() {
    this.slotVisibleDefault = !this.slotVisibleDefault;
    this.slotVisble = !this.slotVisble;
    this.totalSearchQuery.searchValue = '';
  }

  private loading = true;

  private totalSearchQuery: any = {
    searchValue: '',
    industryIdx: null,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    region: '',
    lat: '37.566826',
    lng: '126.9786567',
    adult: 1,
    child: 0,
  }

  private handleClickKeyword(keyword: string) {
    this.totalSearchQuery.searchValue = keyword;
    this.$router.push({ name: 'TotalSearch', query: { ...this.totalSearchQuery } });
  }

  private handleTotalSearch(keyword: string) {
    this.totalSearchQuery.startDate = this.listQuery.startDate;
    this.totalSearchQuery.endDate = this.listQuery.endDate;
    this.$router.push({ name: 'TotalSearch', query: { ...this.totalSearchQuery } });
  }

  private filterDefault: any = {
    date: '',
    personnel: '성인2명 / 아동1명',
    personnelAdult: 2,
    personnelChild: 1,
    village: '',
  }

  private personnellist: any = {
    adult: 1,
    child: 0,
  }

  private recommendKeyword: any = [];

  private recommendlistQuery: any = {
    searchValue: '',
    page: 0,
    size: 12,
  }

  private HotListQuery: any = {
    searchValue: '',
    page: 0,
    size: 10,
  }

  private recommendLoading = false;

  private hotLoading = false;

  private aiSearchLoading = false;

  // 인기 검색어
  private getHotSearchKeyword() {
    this.hotLoading = true;
    getHotSearchKeyword(this.HotListQuery).then((res) => {
      this.hotSearchKeyword = res.data;
      this.hotLoading = false;
    }).catch(() => {
      this.$message.error('인기 검색어를 불러오는데 실패하였습니다.');
    });
  }

  // 추천 검색어
  private getRecommendKeyword() {
    this.recommendLoading = true;
    getRecommendKeyword(this.recommendlistQuery).then((res) => {
      let tmpArray = [];
      const uniqueKeywords: any = new Set();
      tmpArray = res.data;
      // 중복제거
      tmpArray.forEach((item: any) => {
        if (!uniqueKeywords.has(item.keyword)) {
          uniqueKeywords.add(item.keyword);
          this.recommendKeyword.push(item);
        }
      });
      // this.recommendKeyword = tmpArray;
      this.recommendLoading = false;
    }).catch(() => {
      this.$message.error('추천 검색어를 불러오는데 실패하였습니다.');
    });
  }

  // 여기 어때요
  private aiItem = {
    stayList: [],
    restaurantList: [],
    placeList: [],
    leisureList: [],
    excitingList: [],
    productList: [],
  }

  private listQuery: any = {
    searchValue: '',
    industryIdx: null,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    region: '',
    lat: '37.566826',
    lng: '126.9786567',
    adult: 1,
    child: 0,
  }

  private getAiItemList() {
    const lat = getLat();
    const lng = getLng();
    if (lat && lng) {
      this.listQuery.lat = lat;
      this.listQuery.lng = lng;
      this.totalSearchQuery.lat = lat;
      this.totalSearchQuery.lng = lng;
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.listQuery = {
            ...this.listQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          this.totalSearchQuery = {
            ...this.totalSearchQuery,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
      );
    }

    this.aiSearchLoading = true;
    getAiStoreInfoList({
      page: 0,
      size: 4,
      lat: this.listQuery.lat,
      lng: this.listQuery.lng,
    }).then((res) => {
      this.aiItem = {
        ...this.aiItem,
        ...res.data,
      };
      this.aiSearchLoading = false;
    });
  }

  private handleItemClick(idx: any, type: any) {
    if (type === 2) { // 체험
      this.$router.push({ name: 'ExcitingView', params: { idx } });
    } else if (type === 3) { // 레저
      this.$router.push({ name: 'LeisureView', params: { idx } });
    } else if (type === 4) { // 외식
      this.$router.push({ name: 'RestaurantView', params: { idx } });
    } else if (type === 5) { // 일반상품
      this.$router.push({ name: 'GoodsView', params: { uid: idx } });
    } else if (type === 6) { // 관광
      this.$router.push({ name: 'PlaceView', params: { idx } });
    }
  }

  // 캘린더
  private calendarLoading = false;

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private handleChoiceDate(startDate: string, endDate: string) {
    this.selectedDay = startDate;
    this.listQuery.startDate = startDate;
    this.listQuery.endDate = endDate;
  }

  private handleResetFormQuery() {
    this.totalSearchQuery = {
      searchValue: '',
      industryIdx: null,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      adult: 1,
      child: 0,
      page: 0,
      size: 4,
      region: '',
      lat: this.listQuery.lat,
      lng: this.listQuery.lng,
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.removeActiveClass();
  }
}
